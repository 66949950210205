.button {
    @include lightondark;
    @include remove-white-space;

    display: inline-block;
    line-height: 1.3;
    padding: 0;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    margin: 0;
    color: $c-black;
    background: transparent;
    border: 1px solid $c-border;
    border-radius: 3px;
    -webkit-font-smoothing: antialiased; /*make it look nice in webkit*/
    transition: background-color .3s ease, border-color .3s ease, color .3s ease;
    white-space: nowrap;

    a:hover &,
    button:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            color: $c-white;
            background-color: $c-black;
            border-color: $c-black;
        }
    }

    &[disabled],
    &.button--disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;

        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                background-color: $c-grey--dark;
            }
        }
    }

    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0px;
        height: 48px;
    }

    span {
        @include apply-map($f-button);
        @include font(16px, 21px);

        display: inline-block;
        vertical-align: middle;
        padding: 13px 20px;
        white-space: normal;
        pointer-events: none;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    svg {
        transition: fill 200ms ease-in;
    }
}

button {
    width: auto;
    white-space: nowrap;
    vertical-align: top;
    border: none;
}

.button--black {
    background-color: $c-black;
    color: $c-white;
    border-color: $c-black;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            color: $c-black;
            background-color: $c-white;
            border-color: $c-black;
        }
    }
}

.button--white {
    background-color: $c-white;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            color: $c-white;
            background-color: $c-black;
        }
    }

    &[disabled],
    &.button--disabled {
        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                color: $c-grey--mid;
                background-color: $c-white;
            }
        }
    }
}

.button--white-border {
    color: $c-black;
    background-color: $c-white;
    border-color: $c-border;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {

        &:not(.button--no-hover) {
            color: $c-white;
            background-color: $c-black;
        }
    }

    &[disabled],
    &.button--disabled {
        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                color: $c-grey--mid;
                background-color: $c-white;
            }
        }
    }
}

.button--light {
    color: $c-white;
    border-color: transparent;
    background-color: rgba($c-white, 0.25);

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            color: $c-white;
            background-color: $c-black;
            border-color: $c-black;
        }
    }

    &[disabled],
    &.button--disabled {
        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                color: $c-white;
                background-color: rgba($c-white, 0.25);
            }
        }
    }
}

.button--ghost {
    color: $c-black;
    background-color: transparent;
    border-color: $c-border;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            color: $c-white;
            background-color: $c-black;
            border-color: $c-black;
        }
    }

    &[disabled],
    &.button--disabled {
        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                color: $c-grey--mid;
                background-color: transparent;
                border-color: $c-border;
            }
        }
    }
}

.button--light-ghost {
    color: $c-white;
    background-color: transparent;
    border-color: $c-border;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            color: $c-grey--mid;
            background-color: $c-white;
            border-color: $c-white;
        }
    }

    &[disabled],
    &.button--disabled {
        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                color: $c-white;
                background-color: transparent;
                border-color: $c-border;
            }
        }
    }
}

.button--text {
    color: $c-black;
    background-color: transparent;
    border-color: transparent;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            color: $c-white;
            background-color: $c-black;
        }
    }

    &[disabled],
    &.button--disabled {
        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                color: $c-black;
                background-color: transparent;
                border-color: transparent;
            }
        }
    }
}

.button--border {
    color: $c-black;
    background: transparent;
    border-radius: 0;
    border: none;
    text-align: left;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            background-color: transparent;
            border: none;
            color: $c-black;

            span {
                @include border-line-trigger;
            }

            .grey--svg {
                svg {
                    fill: $c-black;
                }
            }
        }
    }

    &::after {
        display: none;
    }

    span {
        @include border-line-grow;
        @include font(18px, 18px);

        letter-spacing: normal;
        padding-top: 0;

        @include breakpoint(tablet) {
            @include font(16px, 16px);
        }
    }

    .grey--svg {
        svg {
            fill: $c-border;
        }
    }

    &.button--has-icon svg {
        margin-left: 10px;
    }
}

.button--border-white {
    color: $c-white;
    background: transparent;
    border-radius: 0;
    border: none;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            color: $c-white;
            border: none;
            background-color: transparent;

            span {
                @include border-line-trigger;
            }
        }
    }

    &::after {
        display: none;
    }

    span {
        @include border-line-grow($border-color: $c-white);
        @include font(18px, 18px);

        letter-spacing: normal;
        padding-top: 0;

        @include breakpoint(tablet) {
            @include font(16px, 16px);
        }
    }

    svg {
        width: 7px;
        height: 10px;
    }
}

.button--link {
    color: $c-black;
    background-color: transparent;
    border-color: transparent;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            color: $c-brand;
            background-color: transparent;
            border-color: transparent;
        }
    }

    &[disabled],
    &.button--disabled {
        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                color: $c-black;
                background-color: transparent;
                border-color: transparent;
            }
        }
    }
}

.button--download {
    display: inline-flex;
    border-color: $c-border;
    color: $c-black;
    justify-content: center;
    background-color: transparent;

    span {
        width: auto;
        padding-right: 15px;
    }

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            color: $c-white;
            background-color: $c-black;

            .button__icon {
                svg {
                    fill: $c-white;
                }
            }
        }
    }

    &[disabled],
    &.button--disabled {
        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                color: $c-grey--mid;
                background-color: $c-white;
            }
        }
    }

    .button__icon {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            fill: $c-black;
            margin: 0;
        }
    }
}

.button--download-white {
    @extend .button--download;
    color: $c-white;

    .button__icon {
        svg {
            fill: $c-white;
        }
    }
}

.button--download-all {
    display: inline-flex;
    border: none;
    overflow: hidden;
    text-align: left;

    a:hover &,
    button:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            background-color: $c-brand;

            .button__icon {
                background: $c-brand;
            }
        }
    }

    &[disabled],
    &.button--disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;

        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                background-color: $c-grey--dark;
            }
        }

        .button__icon {
            &--add {
                display: none;
            }

            &--added {
                display: flex;
            }
        }
    }

    span {
        padding: 12px 12px 12px 24px;
        @include rem(14px);
        letter-spacing: 1px;
        line-height: calc(19 / 14);
        text-align: center;
        color: #FFFFFF;

        @include breakpoint(phablet) {
            @include rem(12px);
            letter-spacing: 0.5px;
            line-height: 15px;
            padding: 9px 10px;
        }
    }

    .button__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 46px;
        width: 46px;
        background: $c-black;
        transition: background-color .3s ease;
        padding-right: 24px;

        svg {
            margin-left: 0;
            fill: $c-white;
        }

        &:hover {
            background: $c-brand;
        }

        &--added {
            display: none;
        }
    }
}

.button--download-reverse {
    display: inline-flex;
    border-color: $c-black;
    color: $c-white;
    background-color: $c-black;
    gap: 15px;
    justify-content: center;

    span {
        width: auto;
    }

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            color: $c-black;
            background-color: $c-white;
            border-color: $c-white;

            .button__icon {
                svg {
                    fill: $c-black;
                }
            }
        }
    }

    &[disabled],
    &.button--disabled {
        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                color: $c-grey--mid;
                background-color: $c-white;
            }
        }
    }

    .button__icon {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            fill: $c-white;
            margin: 0;
        }
    }
}

.button--action {
    display: inline-flex;
    color: $c-black;
    background-color: transparent;
    border-color: $c-border;

    a:hover &,
    button:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {
        &:not(.button--no-hover) {
            color: $c-black;
            border-color: $c-border;
            background-color: $c-grey--light;
        }
    }

    &[disabled],
    &.button--disabled {
        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                color: $c-grey--mid;
                background-color: transparent;
                border-color: $c-border;
            }
        }
    }

    span {
        display: flex;
        flex: 1 1 auto;
    }

        .button--info {
            margin-left: 5px;

            &:before {
                content: '(';
            }

            &:after {
                content: ')';
            }
        }

    .button__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 45px;
        width: 45px;
        border-left: 1px solid $c-border;

        svg {
            margin-left: 0;
        }
    }

        svg {
            fill: $c-brand;
        }
}

.button--anchor {
    @extend .button--border;

    span {
        padding-bottom: 5px;
    }
}

// Icon variants

.button--has-icon {
    span {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: left;

        &.page-intro__basket_count {
            padding: 0 0 0 5px;
            width: auto;
            white-space: nowrap;

            &::before {
                content: "(";
            }

            &::after {
                content: ")";
            }
        }
    }

        svg {
            flex: 0 0 auto;
            margin-left: 20px;
            fill: currentColor;
        }

    &.button--white,
    &.button--ghost {
        a:hover &,
        &:hover,
        &.button--hover,
        a:active &,
        &:active {
            &:not(.button--no-hover) {
                svg {
                    fill: $c-white;
                }
            }
        }

        &[disabled],
        &.button--disabled {
            a:hover &,
            &:hover,
            &.button--hover,
            a:active &,
            &:active {
                &:not(.button--no-hover) {
                    svg {
                        fill: $c-brand;
                    }
                }
            }
        }

        svg {
            fill: $c-black;
        }
    }

    &.button--svg-grey {
        svg {
            fill: $c-grey--dark;
        }
    }
}

.button--has-icon-before {
    @extend .button--has-icon;

    svg {
        margin-right: 10px;
        margin-left: 0;
    }
}

.button--icon {
    width: 35px;
    height: 35px;
    padding: 7px;
    color: $c-black;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {

        &:not(.button--no-hover) {

            svg {
                fill: $c-white;
            }
        }
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0;
    }

        svg {
            fill: currentColor;
        }
}

.button--large-icon {
    @extend .button--icon;

    width: 45px;
    height: 45px;

    &.button--brand {
        color: $c-white;
    }

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {

        &.button--brand:not(.button--no-hover) {

            svg {
                fill: $c-brand;
            }
        }
    }

    span {
        position: relative;
    }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
}

.button--reveal {
    min-width: 35px;

    span {
        padding: 12px 11px 13px 11px;
    }

    .button__text {
        display: none;

        [reveal-button]:not(:hover) & {

            + svg {
                margin-left: 0;
            }
        }

        [reveal-button]:hover & {
            display: block;

            @include breakpoint(tablet) {
                display: none;

                + svg {
                    margin-left: 0;
                }
            }
        }
    }
}

// Centralise the text and the icon (if present)
.button--center {
    text-align: center;

    &.button--has-icon {

        span {
            justify-content: center;
        }
    }
}

// Centralise the text while allowing the icon to be absolute positioned (left or right)
.button--center-text {
    &.button--has-icon {

        span {
            position: relative;
            justify-content: center;
        }

            svg {
                position: absolute;
                margin: 0;
            }
    }
}

.button--center-text-icon-right {
    @extend .button--center-text;

    &.button--has-icon {

        span {
            padding-right: 40px;
        }

            svg {
                right: 20px;
            }
    }
}

.button--center-text-icon-left {
    @extend .button--center-text;

    &.button--has-icon {

        span {
            padding-left: 40px;
        }

            svg {
                left: 20px;
            }
    }
}

.button--state {
    position: relative;
    background-color: transparent;
    border-color: $c-swatch-border;
    color: $c-black;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {

        &:not(.button--no-hover) {
            background-color: $c-white;
            border-color: $c-grey--mid;
        }
    }

    &.button--selected {
        background-color: $c-white;
        border-color: $c-grey--mid;
    }

    &:after {
        height: 38px;
    }

    span {
        @include font(12px, 15px);

        padding-top: 12px;
        padding-bottom: 12px;
        letter-spacing: 0.5px;
    }

    small {
        @include lightText;
        @include font(11px, 14px);

        position: absolute;
        color: $c-black;
        right: 50%;
        bottom: -5px;
        transform: translate(50%, 100%);
    }
}

.button--small-text {
    &::after {
        display: none;
    }

    span {
        @include font(12px, 15px);
        @include lightText;

        padding: 0;
    }
}

// Size variants

.button--small {
    &:after {
        height: 33px;
    }

    span {
        @include font(12px, 15px);

        padding-top: 9px;
        padding-bottom: 9px;
        white-space: nowrap;
    }

    svg {
        max-width: 12px;
    }

    &.button--border {
        @include lightText;

        span {
            padding: 0;
        }
    }
}

.button--small-alt {
    &:after {
        height: 38px;
    }

    span {
        @include font(12px, 15px);

        padding-top: 12px;
        padding-bottom: 12px;
        letter-spacing: 0.5px;
        white-space: nowrap;
    }
}

.button--med-text {
    span {
        @include font(14px, 18px);

        @include breakpoint(tablet) {
            @include font(12px, 16px);
        }
    }
}

.button--box {
    border-radius: 0;
}

// Width variants

.button--full {
    width: 100%;
}

.button--ph-full {
    @include breakpoint(phablet) {
        width: 100%;
    }
}

button::-moz-focus-inner{padding:0;border:none;} /* FF Fix */

.button--bottom-spaced {
    margin-bottom: 10px;

    &:last-child {
       margin-bottom: 0;
    }
}

.button--plain {
    color: $c-black;
    background: transparent;
    border: none;
    text-align: left;

    a:hover &,
    &:hover,
    &.button--hover,
    a:active &,
    &:active {

        &:not(.button--no-hover) {
            background-color: transparent;
            color: $c-brand;
        }
    }

    span {
        @include font(18px, 22px);
        padding-right: 0;
        padding-left: 0;
        text-transform: none;
        letter-spacing: normal;
    }

    svg {
        margin-top: 3px;
    }
}

.button--imports {
    &[disabled],
    &.button--disabled {
        opacity: 0.3;
    }
}
